import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})
export class AuthenticationClientService {
  //baseUrl = "http://192.168.1.73:9090/"; 
   //baseUrl = "http://bravafuel_bam_vivo_back.local:9090/"; 
  //baseUrl =  "http://api.bravafuel.com/api/public/";// from DB server
  baseUrl = "https://api-vem.bravafuel.com/";

  apiClientUrl = this.baseUrl + "api/client";
  apiUrl = this.baseUrl + "api";
  user: any;
  token: any;0
  httpOptions: any;

  constructor(
    public http: HttpClient,
    public nav: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private toastr: ToastrService,

  ) {

    this.token =  sessionStorage.getItem("token");
    this.httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer"+this.token })
    };
  }

  TokenVerificationClient(reponse){
    if(reponse == 401){
      this.storage.clear()
      this.toastr.warning("Votre session a expirée, veillez vous connecter !")
      this.nav.navigate(["/login"])
    }
  }


  // PARTIE CLIENT

  loginClient(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiClientUrl + "/login", data).subscribe(
        (res: any) => {
          sessionStorage.setItem("token", res.data.access_token);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  dashboardData(num_client) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer "+sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl+"/dashboard/client/"+num_client, httpOptions).subscribe(
        (res: any) => {
          resolve(res);
        },
        err => {
          this.TokenVerificationClient(err.status)
          reject(err);
        }
      );
    });
  }


}
