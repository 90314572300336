import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/apiService';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';

@Component({
  selector: 'app-login-forget',
  templateUrl: './login-forget.component.html',
  styleUrls: ['./login-forget.component.scss']
})
export class LoginForgetComponent implements OnInit {

  loginForgetData: any = {};
  mess = {error:true};
  messageToshow="";
  constructor(
    @Inject(LOCAL_STORAGE)
   private storage: StorageService,
   public api: AuthenticationService,
   public dialogRef: MatDialogRef<LoginForgetComponent>,
   public toast: ToastrService,
   public dialog: MatDialog,@Optional() @Inject(MAT_DIALOG_DATA)
   public param: any,
   private loader: NgxUiLoaderService,
   private nav: Router,
  ) {
      dialogRef.disableClose = true;
   }

  ngOnInit() {
     console.log("donné mis en parametre",this.param)

  }

  changeLogincredentials(){
    if(this.param.type=="admin"){
      this.adminPasswordForget();
    }else{
      this.clientPasswordForget();
    }
  }

  adminPasswordForget(){
    this.loader.startLoader("loader-02");
    let data ={'email':this.loginForgetData.email};
    this.api.adminPasswordForget(data).then((res:any)=>{
      this.loader.stopLoader("loader-02");
      if(!res.error){
         this.toast.success('Demande de réinitialisation de mot de passe prise en compte');
      }else{
         this.toast.error(res.result);
      }

      this.mess= res;
    }).catch(err=>{
      console.log(err)
      this.loader.stopLoader("loader-02");
      this.toast.error(err.error.result)
    })
  }

  clientPasswordForget(){
    this.loader.startLoader("loader-02");
    let data ={'email':this.loginForgetData.email};
    this.api.clientPasswordForget(data).then((res:any)=>{
      this.loader.stopLoader("loader-02");
      if(!res.error){
        this.toast.success('Demande de réinitialisation de mot de passe prise en compte');
      }else{
        this.toast.error(res.result);
      }
      this.mess= res;
    }).catch(err=>{
      console.log(err)
      this.loader.stopLoader("loader-02");
      this.toast.error(err.error.result)
    })
  }

  closeModal(data){
    console.log(this.param.type)
    this.dialogRef.close(data);
    this.storage.clear();
    if(this.param.type=="admin")
    {
      console.log("portail Admin")
      this.nav.navigate(['/admin/login'])
    }
    else{
      console.log("Portail client")
      this.nav.navigate(['/login'])
    }

  }

}
