import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  baseUrl =  "https://api-vem.bravafuel.com/"; 
  //baseUrl = "http://192.168.1.73:9090/bravafuel_bam_vivo_back/public/";
   //baseUrl = "http://localhost:9090/bravafuel_bam_vivo_back/public/";
  apiAdminUrl = this.baseUrl + "api/admin";
  apiUrl = this.baseUrl + "api";
  user: any;
  token: any;
  httpOptions: any;

  constructor(
    public http: HttpClient,
    public nav: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private toastr: ToastrService,

  ) {

    this.token = sessionStorage.getItem("token");
    this.httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer" + this.token, "Access-Control-Allow-Origin": '*' })
    };

  }

  async TokenVerification(reponse) {
    console.log('reponsse', reponse)
    if (reponse == 401) {
      this.toastr.warning("Votre session a expirée, veillez vous connecter !", '', { timeOut: 5000 })
      let userData: any = await this.storage.get("user")
      console.log("TokenVerification", userData)
      if (userData) {
        this.nav.navigate(["/login"]);
      } else {
        this.nav.navigate(["/admin/login"]);
      }
      this.storage.clear()
    }
  }


  // PARTIE ADMINISTRATEUR

  loginAdmin(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/login", data).subscribe(
        (res: any) => {
          sessionStorage.setItem("token", res.data.api_token);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeAdminPass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/change_administrator_password", data).subscribe(
        (res: any) => {
          sessionStorage.setItem("token", res.data.api_token);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  resetAdminPass(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    console.log("httpOptions");
    console.log(httpOptions);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/reset_password", data,httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  changeClientPass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/change_client_password", data).subscribe(
        (res: any) => {
          sessionStorage.setItem("token", res.data.api_token);
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  updateAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  checkSession() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/checkSession", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  desableAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/desable_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  enableAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/enable_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAdmin() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_administrator", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addAdmin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_administrator", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getAdminById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_administrator/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listProfil() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_profil", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addProfil(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_profil", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getPofilById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_profil/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAccess() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_access", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listAccessMenu() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_access_menu", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addAccess(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_access", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getAccessById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_access/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  // deleteEntity(data) {
  //   let httpOptions = {
  //     headers: new HttpHeaders({ Authorization: "Bearer "+sessionStorage.getItem("token") })
  //   };
  //   //console.log("token@@@@",sessionStorage.getItem("token"))
  //   return new Promise((resolve, reject) => {
  //     this.http.post(this.apiAdminUrl+"/delete_access", data, httpOptions).subscribe(
  //       (res: any) => {
  //         resolve(res);
  //       },
  //       err => {
  //         this.TokenVerification(err.status)
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  updateEntity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listEntity() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_entity", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addEntity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getEntityById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_entity/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }


  updateEntityType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity_type", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listEntityType() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_entity_type", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addEntityType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_entity_type", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getEntityTypeById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/view_entity_type/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }




  updateClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientCategory() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cat/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClientCategoryById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cat/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteClientCategory(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cat/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  updateClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateClientSegment(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_segment/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientType() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientSegment() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_segment/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  listClientDiscountTable(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/getClientDiscountsTable/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  addClientSegment(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_segment/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClientTypeById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_type/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }


  getClientSegmentById(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_segment/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          reject(err);
          this.TokenVerification(err.status)
        }
      );
    });
  }

  deleteClientType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteClientSegment(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_segment/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /**Gestion des client  */
  createClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientAndSubmit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/create_submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/bulk_create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createClientBulkAndSubmit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/bulk_create_submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  submitClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/submit", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientValidDc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/sd_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientRejetDc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/remove_submision", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ClientValidEc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/compliance_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientRejetEc(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/remove_sd_approbation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ClientSearch(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/search/" + data.seach_mode + "/" + data.keyword, data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientSoumission() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/list_clients_to_submit", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientValidCd() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/client_to_validate_by_cd", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClient() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientValidEc() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client/client_to_validate_by_compliance", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  disableClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/disable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  enableClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/client/enable", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listCardFree() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////////////////////Enregistrer les cartes en masse ////////////////////
  saveCarteBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/create_bulk", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bulkCardImport(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/free_cards/import", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listClientCard() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  listClientCardMigrationReport() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/cardMigrationReport", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //** GESTION DES CARTES */
  get_carte_vierge(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_carte_status() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_carte_type() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_cites() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/city/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  get_produit_list() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  creer_une_carte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  search_carte_user(search_mode, keyword) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/search/" + search_mode + "/" + keyword, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  // PARTIE CLIENT
  //////////////////////////liste des produits

  listProduit() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  list_for_br_creation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/product/list_for_br_creation", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////// save Product

  // LISTE TYPE DE CARTE
  ListTypeCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // CREATION TYPE DE CARTE

  createTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  deleteTypeCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // LISTE
  ListDesRegion() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/region/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  CreateRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  UpdateRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  DeleteRegion(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/region/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Carte

  ListDesCarteState() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/card_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  CreateCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  UpdateCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteCardState(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/card_state/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchCardByNumber(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/" + card_number, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  getValidCardByNum(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/getValidCard/" + card_number, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardOpposition(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/opposition", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardLeveOpossition(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/removeOpposition", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cardRevalidation(card_number) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/revalidation", { card_number: card_number }, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  UpdateClientCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  DuplicateClientCard(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/duplicate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////// save Product

  saveProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////// Delete Product
  deleteProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////////Update Produit
  updateProduit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/product/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////// Liste city
  listCity() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/city/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////save city
  saveCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////////////// Upate city
  updateCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////delete city
  deleteCity(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/city/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////List stationsManager
  listStationManager() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_manager/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  stationByManager(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/stationByManager",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  freeCardCount() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/count", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////////////////Save Station Manager
  saveStationManager(data) {
    console.log("iccicici");
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Update Station Manager
  updateStationManager(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete Station Manager
  deleteStationManager(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_manager/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////// List stations
  listStation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_station", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete station
  deleteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Save Station
  saveStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_station", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////Update Station
  updateStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token")})//, enctype: "multipart/form-data"
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////// List Des commandes articles
  listOrdrers() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/orders/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ////////////////////// Save Orders Articles
  saveOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ////////////////////// update Orders Articles
  updateOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////delete Orders Articles
  deleteOrdersArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////Etat Livraison order article
  deliveryOrdersArticles(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/orders/deliver", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////////////////////// print PDF //////////////////////////////////////////////////////////////

  //////////// informations vivo
  getInfoVivo() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: "Bearer " + sessionStorage.getItem("token")
      })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/vivo", optionRequete).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////// informations Bank
  getInfoBank() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/bank", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////// informations Global
  getInfoGlobal() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token"), enctype: "multipart/form-data" })
    };
    const optionRequete = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: "Bearer " + sessionStorage.getItem("token")
      })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/global", optionRequete).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////////////////////////////////////////////////////////////////////////


  ////////////////////liste des pompiste

  ListPompistStation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_attendant/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

ListPompistStationByStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/station_attendant/by_station/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createPompisteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updatePompisteStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ListStationPompiste() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_station", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  active_pompiste(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/active", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  desactive_pompiste(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/desactive", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  reset_pompiste_pin(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/station_attendant/resset_pin", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  //======================= REPORTING +++++++++++++++++++++++++++++++++
  cardGlobalSettings() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/setting_by_type/card", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  getsettingByKey(key) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/global_settings/getSettingsBykey/"+key, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchCardClientByClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/search/" + data.seach_mode + "/" + data.keyword, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /// ARTICLE
  ListDesArticles() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/articles/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteArticle(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/articles/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //agence banque
  ListAgenceBanque() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/list_bank_agency", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/create_bank_agency", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/update_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  deleteAgenceBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiAdminUrl + "/delete_entity", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Type transaction

  ListTypeTransaction() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact_type/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  createTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  updateTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteTypeTransaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact_type/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///// Banque
  ListDesBanques() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bank/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///// Liste des demande de réinitialisation de PIN
  CardPinResetRequestList() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/pin/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  
  ///// Liste des alertes transactinis
  TransactionAlertList(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/alert_transactions/list/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

    
  ///// Liste des alertes transactinis
  ProcessTransactionAlertList(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/alert_transactions/process_full",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }    


  ///// Liste des alertes transactinis
   SearchTransactionAlertList(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/alert_transactions/search",data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  createCardPinResetRequest(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pin/createCardPinResetRequest", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  activeCardPinResetRequest(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pin/activeCardPinResetRequest", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  desactiveCardPinResetRequest(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pin/desactiveCardPinResetRequest", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  deleteBanque(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bank/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///// Mode Paiement

  ListPayMode() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/pay_mode/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  createPayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updatePayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deletePayMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/pay_mode/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////// search BR
  searchBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////list Br a effacer
  listBordereau() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Payer Bordereau
  payerBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/pay", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Annuler Bordereau
  cancelBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //////////////Valider Bordereau
  validateBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/validate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Generer Paie PDF Bordereau
  bordereauGenerationPayPDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////////// details de bordereau
  detailsBordereau(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ///////listes des clients-utilisateurs
  ListClientsUsers() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_user/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////save clients-utilisateurs
  SaveClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////update clients-utilisateurs
  UpdateClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  ///////delete clients-utilisateurs
  DeleteClientsUsers(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  resetClientPass(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_user/reset_password", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  //// Status bordereau
  ListMotifs() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/request_reason/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //// Status bordereau
  ListMotifsBrExpertCancellation() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/request_reason/list_br_expert_cancelation", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //// Status bordereau
  ListMotifsCLientRejet() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/request_reason/list_client_reject", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  updateMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteMotif(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/request_reason/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // Liste statuts br
  listStatuBr() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/request_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  // Recherche bordereaux

  SearchNumClient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClient/" + data.client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  SearchCodeBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/" + data.code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/ByDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateStatus(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/search/" + data.state_code + "/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBySap(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClientSap/" + data.client_sap, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  detailSearchBR(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))br/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //BORDEREAU

  bordereauCreate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauUpdate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauByNumClient(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauPayInvoicePDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_invoice/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

bordereauGenerationCreatePDF(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/print_br_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cancelExpertBordereau(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/br/expert_cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  //BORDEREAU de carte


  // Liste statuts br
  listStatuBrCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/request_state/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
/////////////////////////////////////////// Liste des bordereaux Validés ///////////////////////
  listBorderauxValidate() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/validated/AllBcPendingCardProduction", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  /////////////////////////////////////////// Liste des cartes  ///////////////////////
  listFreeCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/free_cards/getRandomFreeCardList/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  // Recherche bordereaux

  SearchNumClientCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClient/" + data.client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  SearchCodeBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/" + data.code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/ByDate/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchDateStatusBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/search/" + data.state_code + "/" + data.start + "/" + data.end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBySapBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClientSap/" + data.client_sap, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  detailSearchBrCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))bcc/ByDate/{start}/{end}
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //BORDEREAU

  bordereauCreateCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauUpdateCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauByNumClientCarte(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/byClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauGenerationCreatePDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cancelExpertBordereauCarte(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/expert_cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ///////// search BR
  searchBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////list Br a effacer
  listBordereauCarte() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Payer Bordereau
  payerBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/pay", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Annuler Bordereau
  cancelBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/cancel", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Valider Bordereau
  validateBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/bcc/validate", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  //////////////Generer Paie PDF Bordereau
  bordereauGenerationPayPDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_order/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bordereauGenerationRecusPDFCarte(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/print_bcc_invoice/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  /////////////////////////////// details de bordereau
  detailsBordereauCarte(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/bcc/getLines/" + data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  /////////////////////////////// RECHERCHE DE TRANSACTION
  search_transaction(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/search", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listTransactionByClient(client_num) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getTransactionByClient/" + client_num, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  transactionDetails(ref) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getTrabsactionDetails/" + ref, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  getRBalanceRestitutionTransactionDetails(card_number,amount) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getRBalanceRestitutionTransactionDetails/" + card_number+"/"+amount, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  // PROFORMA
  listProforma() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  listProformaByClient(num_client) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/byCLient/" + num_client, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  createProforma(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateProforma(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteProforma(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/proforma/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  proformaGenerationPayPDF(code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/proforma/topdf/" + code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //GESTION TPE

  listTpe() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/tpe/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  assigneTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/assign", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  activeTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/active", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  desactiveTpe(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/tpe/desactive", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  repordingGlobal(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/activity_recap", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  ReclamationCreate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/create", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  ReclamtionsList() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/list", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getClaimsByStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/getClaimsByStation/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  deleteReclamation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/delete", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  UpdateReclamation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/claims/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getReclamationByClient(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/getClaimsByClient/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  getReclamationByStation(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/claims/getClaimsByStation/" + id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //setting

  getSettingType(type = null) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };

    let url: string = "";

    if (type) {
      url = "/global_settings/setting_by_type/" + type;
    } else {
      url = "/global_settings/getSettingsType";
    }
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + url, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  updateSetting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/global_settings/update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  updateSettingBulk(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/global_settings/bulk_update", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  balanceStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/balanceStation", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  GlobalebalanceStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/balanceStationAll", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  paymentReporting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentReporting", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  CLientCardReporting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/clientCardReporting/"+ data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  sapReporting(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/sap/list", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  kpiFinanceSearch(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getFinanceKPI", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cscKpiSearch(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getFinanceKPI", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  exportSapFile(data) {

    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/sap/exporte_sap", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  //DASCHBOARD
  dashboardData() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/dashboard_boxes", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   dashboardDataByStation(station_id,start, end) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/dashboard_boxes_station/"+station_id+"/"+start+"/"+end, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartByMonth(month, year) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_sale_per_month/" + month + "/" +year, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartByMonthByStation(month,station_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_sale_per_month_by_station/" + month+"/"+station_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  chartHistogrammeByYear(year) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram/" + year, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  chartHistogrammeByYearByStation(year,staton_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram_by_station/" + year+"/"+staton_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  iniChartByTransactBySationPerMont(month,staton_id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/reporting/chart_activity_histogram_by_station_per_month/" + month+"/"+staton_id, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByType(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTransactionCAbyType" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  catransactionByTypeAndStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTransactionCAbyType_by_station" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByMode(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentReportingByPayMode" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  catransactionByProduct(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentByProducts" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  catransactionByProductAndStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getPaymentByProducts_by_station" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  clientRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getClientTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  stationRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getStationTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  getTop10CashTransactByStation(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getTop10CashTransactByStation" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  top10CclientConsumeData(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getClientTop10Consumation" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  top10CardDebit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/top10CardDebit" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  top10CardCredit(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/top10CardCredit" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  bankRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getBankAgencyTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  cartRankTop(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/reporting/getCardTop10" , data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  GenerateCratedCardBulk(br_code) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/generateCreatedCardSheet/" + br_code, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }



  adminActivation(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/admin_activation/" + remember_token, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  clientActivation(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/client_activation/" + remember_token, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   adminPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/admin/password_forget", data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   rememberTokenAdminCheck(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiAdminUrl + "/forget_password_admin/" + remember_token, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

     changeAdminPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/admin/change_forget_password_admin", data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   /////client password forget
  clientPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client/password_forget", data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
        );
      });
    }

      changeClientPasswordForget(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client/change_forget_password_client", data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

    rememberTokenClientCheck(remember_token) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client/forget_password_client/" + remember_token, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

listReportingTelecollecte(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/reporting/reporting_telecollecte", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res)
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  bordereauInPending() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/list_pending/all", httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBordereauInPendingbyClientName(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/br_pending/byclientName/"+data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   searchBordereauInPendingbyClientSap(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/br_pending/byclientSap/"+data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   searchBordereauInPendingbyCardNum(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/br_pending/byNumCarte/"+data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchBordereauInPendingbyCodeBr(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/br_pending/bycodeBR/"+data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  exportTransactionFile(data) {

    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };

    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/reporting_transaction/"+data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  searchTransfertBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/br_transfer/search", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res)
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  searchRepositingBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/br_repositing/search", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res)
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  TransfertBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/transfer", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res)
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  repositingBrData(data) {
  let httpOptions = {
    headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
  };
  //console.log("token@@@@",sessionStorage.getItem("token"))
  return new Promise((resolve, reject) => {
    this.http.post(this.apiUrl + "/br/repositing", data, httpOptions).subscribe(
      (res: any) => {
        this.setToken(res)
        resolve(res);
      },
      err => {
        this.TokenVerification(err.status)
        reject(err);
      }
      );
    });
  }

  getClientCardValidate(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/byClient/card_validate/"+data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  unlockChargement(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/unlock_br_line/"+ data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
   listunlockChargement() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/br/locked_charge_list", httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

   getAmountRestitution() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/getAmountRestitution", httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }
  
  validateReposition(id) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/transact/validateAmountRestitution/"+id, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


saveRepositionnement(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/transact/saveAmountRestitutionLoad",data, httpOptions).subscribe(
        (res: any) => {
         this.setToken(res)
          resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  saveExpressCardId(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/saveExpressCardId", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  } 
  
  searchCardExpress(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/searchCardExpress", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  expressCardList() {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + "/client_cards/getCardExpressList", httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }


  setToken(res){
     if(res.api_token && res.api_token != null){
            sessionStorage.setItem("token", res.api_token);
            //console.log(res.api_token)
      }
  }

  checkcardclient(data) {
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") })
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/checkCardCLients", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

  migrateCard(data) {
    let httpOptions: any = {
      headers: new HttpHeaders({ Authorization: "Bearer " + sessionStorage.getItem("token") }),
      responseType: 'arraybuffer'
    };
    //console.log("token@@@@",sessionStorage.getItem("token"))
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + "/client_cards/createClientCardsMigration", data, httpOptions).subscribe(
        (res: any) => {
          this.setToken(res);
           resolve(res);
        },
        err => {
          this.TokenVerification(err.status)
          reject(err);
        }
      );
    });
  }

}
