import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationService } from './services/apiService';
import { AuthenticationClientService } from './services/apiServiceClient';
import { HttpClientModule } from '@angular/common/http';

//import { StorageServiceModule } from "ngx-webstorage-service";

import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { LoginResetComponent } from './login.reset/login.reset.component';
import { LoginForgetComponent } from './login-forget/login-forget.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { StorageServiceModule } from 'ngx-webstorage-service';
registerLocaleData(localeFr);





@NgModule({
  declarations: [AppComponent,LoginResetComponent,LoginForgetComponent ],
  entryComponents:[LoginResetComponent,LoginForgetComponent
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    //StorageServiceModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    MatDialogModule,


  ],
  providers: [AuthenticationService,AuthenticationClientService,{ provide: LOCALE_ID, useValue: 'fr-FR'}],
  bootstrap: [AppComponent],

})
export class AppModule { }
